import React from 'react';
import ReactDOM from 'react-dom';

import './assets/styles/Global.module.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import App from './routes';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);