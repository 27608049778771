import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core';

const Input = ({ name, fnMask, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  function handleOnChange() {
    if (inputRef.current?.value) {
      inputRef.current.value = fnMask(inputRef.current.value);
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField]);
  
  if (fnMask) {
    return (
      <input
        name={name}
        ref={inputRef}
        onChange={handleOnChange}
        type="text"
        {...rest}
      />
    );
  }

  return (
    <input
      name={name}
      ref={inputRef}
      type="text"
      {...rest}
    />
  )
}

export default Input