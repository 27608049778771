import { useContext } from 'react';

import ToastContext from './../contexts/ToastContext';

export function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('Toast context not found, useToast must be used with a Provider.');
  }

  return context;
}