export const getAssignees = async () => {
    try {
        const assignees = {
          1: "Contemplato",
          2: "FIDC Contemplato",
          3: "Terceiros",
          4: "BTG Pactual",
          5: "Rodobens",
        }
        
        return assignees;
    } catch (err) {
        console.log(err)
        throw err;
    }
}
