import { useState } from 'react';
import { AiFillTrophy } from 'react-icons/ai';
import { RiMore2Fill } from 'react-icons/ri';

import formatters from '../../../utils/formatters';

// import { useTalk } from './../../../hooks/useTalk';
import { useModal } from '../../../hooks/useModal';
import { useToast } from './../../../hooks/useToast';

import { openVollChat } from '../../../services/operator.service';

import TableModal from './../../Modals/TableModal';
import TableMenuModalComponent from './../TableMenuModalComponent';

import styles from './../../../assets/styles/MyDay.module.scss';

function PhoneNumberComponent({ isPrimary, id, name, phoneNumber, handleSetPhone, handleRemovePhone }) {
  const [isOpen, setIsOpen] = useState(false);
  
  const { handleOpenModal, handleCloseModal } = useModal();
  const { notifySuccess, notifyDanger } = useToast();
  // const { isLoggedTalk, handleStartCall } = useTalk();

  function handleTogglePhoneModal() {
    setIsOpen((currIsOpen) => !currIsOpen);
  }

  function handleMakeCall() {
    handleTogglePhoneModal();
    // handleStartCall({ phone: phoneNumber, proposalId: id, name: name })
  }

  async function handleOpenVollChat() {
    try {
      handleTogglePhoneModal();
      
      await openVollChat(id);
      
      notifySuccess('Voll chat aberto com sucesso.')
    } catch(err) {
      notifyDanger(err.message)
    }
  }
  
  function handleSetDefaultPhone() {
    handleTogglePhoneModal();
    handleSetPhone({ id, phoneNumber });
  }
  
  function handleDeletePhone() {
    handleRemovePhone({ id, phoneNumber });
  }

  function handleShowDeletePhoneModal() {
    handleTogglePhoneModal();

    const modalInfo = {
      phone: formatters.phoneBrazil(phoneNumber),
    }

    handleOpenModal({
      content: <TableModal type="delete-phone" onClose={handleCloseModal} infos={modalInfo} onSubmit={handleDeletePhone} />
    });
  }


  return (
    <div>
      {isOpen && (
        <TableMenuModalComponent onClose={handleTogglePhoneModal}>
          <div>
            {/* {isLoggedTalk && <a onClick={handleMakeCall}>Fazer ligação</a>} */}
            <a onClick={handleOpenVollChat}>Abrir voll chat</a>
            {!isPrimary && (
              <>
                <a onClick={handleSetDefaultPhone}>Definir telefone principal</a>
                <div />
                <a className={styles.DeletePhone} onClick={handleShowDeletePhoneModal}>Deletar</a>
              </>
            )}
          </div>
        </TableMenuModalComponent>
      )}
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '160px' }}>
        <span>{formatters.phoneBrazil(phoneNumber)}</span>
        {isPrimary && <AiFillTrophy color="#FFD600" size={24} style={{ marginLeft: '8px' }} />}
      </div>
      <button type="button" onClick={handleTogglePhoneModal}>
        <RiMore2Fill size={20} color="#838FBA" />
      </button>
    </div>
  )
}

export default PhoneNumberComponent;