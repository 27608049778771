import React, { useCallback, useState } from 'react'
import Calendar from 'react-day-picker';
import moment from 'moment';

import 'react-day-picker/lib/style.css';

export default function DatePicker({ defaultDate, onUpdateDate }) {
  let dayIncrement = 1;

  if (moment().day() === 5) {
    dayIncrement = 3;
  } else if (moment().day() === 6) {
    dayIncrement = 2;
  }

  const nextBusinessDay = moment().add(dayIncrement, 'days').toDate();
  const toMonth = moment().add(2, 'months').toDate();
  
  const [selectedDate, setSelectedDate] = useState(() => {
    onUpdateDate(defaultDate || nextBusinessDay);
    
    return defaultDate || nextBusinessDay;
  });
  
  const handleDateChange = useCallback((day, modifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      onUpdateDate(day);
      setSelectedDate(day);
    }
  }, [onUpdateDate]);
  
  return (
    <Calendar
      weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
      fromMonth={moment().toDate()}
      showOutsideDays
      toMonth={toMonth}
      disabledDays={[
        {
          daysOfWeek: [0, 6],
        },
        {
          before: nextBusinessDay,
        },
      ]}
      modifiers={{
        available: { daysOfWeek: [1, 2, 3, 4, 5] },
      }}
      selectedDays={selectedDate}
      onDayClick={handleDateChange}
      months={[
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]}
    />
  )
}
