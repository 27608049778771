import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core';

const Textarea = ({ name, fnMask, ...rest }) => {
  const textareaRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  function handleOnChange() {
    if (textareaRef.current?.value) {
      textareaRef.current.value = fnMask(textareaRef.current.value);
    }
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField]);
  
  if (fnMask) {
    return (
      <textarea
        name={name}
        ref={textareaRef}
        onChange={handleOnChange}
        type="text"
        {...rest}
      />
    );
  }

  return (
    <textarea
      name={name}
      ref={textareaRef}
      type="text"
      {...rest}
    />
  )
}

export default Textarea;