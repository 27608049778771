import React, { useState } from 'react'

import ModalAlert from './../components/Modals/ModalAlert';

import ModalContext from './../contexts/ModalContext';

export default function Modal({ children }) {
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    content: null,
  });

  function handleCloseModal() {
    setModalConfig({
      content: null,
      isOpen: false,
    });
  }

  function handleOpenModal({ content }) {
    setModalConfig({
      content,
      isOpen: true,
    });
  }

  return (
    <ModalContext.Provider value={{ handleOpenModal, handleCloseModal }}>
      {modalConfig.isOpen && (
        <ModalAlert onClose={handleCloseModal}>
          {modalConfig.content}
        </ModalAlert>
      )}
      {children}
    </ModalContext.Provider>
  )
}
