import React, { useState } from 'react';
import { RiMore2Fill } from 'react-icons/ri';

import styles from './../../../assets/styles/Table.module.scss';

import TableMenuModalComponent from './../TableMenuModalComponent';
import TableMenu from './../TableMenu';

function TableMenuColumnComponent({ handleRemoveProposal, row, handleUpdateDate, handleUpdateOwner }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOnToggleModal() {
    setIsOpen((currIsOpen) => setIsOpen(!currIsOpen));
  }

  return (
    <div className={styles.Container}>
      <button type="button" onClick={handleOnToggleModal} className={styles.ButtonToggleModal}>
        <RiMore2Fill size={24} color="#838FBA" />
      </button>
      {isOpen && (
        <TableMenuModalComponent onClose={handleOnToggleModal}>
          <TableMenu handleRemoveProposal={handleRemoveProposal} handleUpdateDate={handleUpdateDate} row={row} handleUpdateOwner={handleUpdateOwner} />
        </TableMenuModalComponent>
      )}
    </div>
  );
}

export default TableMenuColumnComponent;