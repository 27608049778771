const status = {
  BACKLOG: 'backlog',
  INPROGRESS: 'in_progress',
  INNEGOTIATION: 'in_negotiation',
  ACCEPTED: 'accepted',
  DENIED: 'denied',
  NORESPONSE: 'no_response',
  DISCARDED: 'discarded',
}

export default status;