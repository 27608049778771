import React, { useRef } from 'react';

import { useOutsideAlerter } from './../../../hooks/useOutsideAlerter';

import styles from './../../../assets/styles/Table.module.scss';

function TableMenuModalComponent({ onClose, children }) {
  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, onClose);

  return (
    <div ref={modalRef} className={styles.TableMenuModalContainer}>
      {children}
    </div>
  )
}

export default TableMenuModalComponent;