import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import Confetti from 'react-confetti';

import { proposalReducer } from './../reducers/proposalReducer';

import Header from './../components/Header';
import Table from './../components/Table';
import TableMenuColumnComponent from './../components/Table/TableMenuColumnComponent';

import styles from './../assets/styles/MyDay.module.scss';

import formatters from './../utils/formatters';

import { useWindowDimensions } from './../hooks/useWindowDimensions';
import { useEnhancedReducer } from './../hooks/useEnhancedReducer';
import { useToast } from './../hooks/useToast';

import { getAssignedToOperator } from './../services/operator.service';
import { getAssignees } from './../services/assignee.service';

export default function MyDay() {
  const [errorMessage, setErrorMessage] = useState('');
  const [assignees, setAssignees] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [proposals, dispatch, getState] = useEnhancedReducer(proposalReducer, []);

  const { width, height } = useWindowDimensions();

  const { notifyDanger } = useToast();

  const columnWidth = useMemo(() => `${(width - 60) / 7}px`, [width]);

  useEffect(() => {
    async function requestProposalToOperator() {
      try {
        const proposalData = await getAssignedToOperator();
        const assigneeData = await getAssignees();
        setAssignees(assigneeData);
        dispatch({ type: 'FETCHED_PROPOSAL', proposals: proposalData });
      } catch (err) {
        notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
        setErrorMessage(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    requestProposalToOperator();
  }, [notifyDanger, dispatch]);

  async function handleUpdateOwner({ id, operatorId }) {
    dispatch({ type: 'UPDATE_PROPOSAL_OWNER', operatorId, id });

    handleRemoveProposal(id);
  }

  function handleRemoveProposal(id) {
    dispatch({ type: 'REMOVE_PROPOSAL', id });
  }

  async function handleUpdateDate({ id, description, date }) {
    dispatch({ type: 'UPDATE_PROPOSAL_DATE', id, description, date });
  }

  function handleSetProposals(proposalsToUpdate) {
    dispatch({ type: 'FETCHED_PROPOSAL', proposals: proposalsToUpdate });
  }

  function handleGetProposals() {
    return getState();
  }

  const columns = [
    {
      headerName: 'Nome',
      field: 'name',
      width: columnWidth,
      readonly: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      type: 'select',
      width: columnWidth,
      readonly: true,
      selectOptions: {
        'no_response': "Sem resposta",
        'in_negotiation': "Em negociação",
      },
      renderCell: (props) => {
        const { cell, row } = props;

        const lastContactDate = moment(row.lastContactDate).locale('pt-br').format("DD/MMM/YYYY");

        const options = {
          'no_response': "Sem resposta",
          'in_negotiation': "Em negociação",
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#E6E6F2',
                padding: '2px 5px',
                borderRadius: '10px',
                justifyContent: 'center',
                flex: '0 0 0',
              }}
              >
                <div style={{
                  borderRadius: '100%',
                  backgroundColor: '#4A72FF',
                  width: '6px',
                  height: '6px',
                }}
                />
                <span style={{
                  marginLeft: '5px',
                  fontWeight: 500,
                  color: '#4A72FF',
                  fontSize: '12px',
                }}
                >
                  {options[cell]}
                </span>
              </div>
            </div>
            <span style={{
              color: '#6E6893', fontWeight: 500, fontSize: '12px', marginTop: '5px',
            }}
            >
              Último contato: {`${lastContactDate}`}
            </span>
          </div>
        );
      },
    },
    {
      headerName: 'Telefone Principal',
      field: 'phone',
      readonly: true,
      width: columnWidth,
      renderCell: (props) => {
        const { cell } = props;

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <span>
                {cell?.toString().length < 12 ? formatters.phoneBrazil(cell) : cell}
              </span>
            </div>
            <span style={{
              color: '#6E6893', fontWeight: 500, fontSize: '12px', marginTop: '5px',
            }}
            >
              Canal Recomendado: Ligação
            </span>
          </div>
        );
      },
    },
    {
      headerName: 'Valor da oferta',
      field: 'finalValue',
      readonly: true,
      renderCell: ({ cell }) => <span>{formatters.financeReal(cell)}</span>,
      width: columnWidth,
    },
    {
      headerName: 'Cessionário',
      field: 'assigneeId',
      type: 'select',
      selectOptions: assignees,
      readonly: true,
      width: columnWidth,
    },
    {
      headerName: 'Ultima atualização',
      field: 'updatedAt',
      readonly: true,
      width: columnWidth,
      renderCell: (props) => moment(props.cell).format('DD/MM/YYYY'),
    },
    {
      headerName: '',
      width: columnWidth,
      hideFilter: true,
      renderCell: (props) => {
        return (
          <TableMenuColumnComponent handleRemoveProposal={handleRemoveProposal} row={props.row} handleUpdateDate={handleUpdateDate} handleUpdateOwner={handleUpdateOwner} />
        )
      }
    },
  ];

  if (errorMessage) {
    return (
      <div className={styles.Container}>
        <Header />
        <div className={styles.TableContainer}>
          <div className={styles.DoneContainer}>
            <BsFillXCircleFill color="#F53649" size={40} />
            <h1>Ocorreu algum erro!</h1>
            <span>Mensagem de erro: <b>{errorMessage}</b></span>
          </div>
        </div>
      </div>
    )
  }

  if (proposals.length === 0 && !isLoading) {
    return (
      <div className={styles.Container}>
        <Header />
        <div className={styles.TableContainer}>
          <div className={styles.DoneContainer}>
            <BsFillCheckCircleFill color="#00C853" size={40} />
            <h1>Meta Completa!</h1>
            <span>Por hoje é só</span>
            <Confetti numberOfPieces={150} width={width - 20} height={height} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.Container}>
      <Header />
      <div className={styles.TableContainer}>
        <Table
          columns={columns}
          isLoading={isLoading}
          handleSetProposals={handleSetProposals}
          handleGetProposals={handleGetProposals}
        />
      </div>
    </div>
  )
}
