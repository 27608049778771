import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { useToast } from './../../hooks/useToast';
import { useAuth } from './../../hooks/useAuth';

import { ReactComponent as Logo } from './../../assets/images/logo-contemplato.svg';

import styles from './../../assets/styles/Header.module.scss';


export default function Header() {
  const { pathname } = useLocation();
  const { notifyDanger } = useToast();
  const { handleSignOut } = useAuth();

  const routesWithStyles = {
    '/': {
      className: 'normal-link',
      name: 'Meu dia'
    },
    '/schedules': {
      className: 'normal-link',
      name: 'Meus agendamentos'
    },
    '/complete-vision': {
      className: 'normal-link',
      name: 'Visão completa'
    },
    // '/dashboard': {
    //   className: 'normal-link',
    //   name: 'Dashboard'
    // },
  }

  if (routesWithStyles[pathname]?.className) {
    routesWithStyles[pathname].className = styles.SelectedPath;
  }

  const routes = Object.keys(routesWithStyles);

  async function handleLogoutOperator() {
    try {
      await handleSignOut();
    } catch(err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  return (
    <header className={styles.Container}>
      <Link to="/">
        <Logo />
      </Link>
      <div>
        <div>
          {routes.map((route) => <Link key={route} className={routesWithStyles[route].className} to={route}>{routesWithStyles[route].name}</Link>)}
        </div>
        <button type="button" onClick={handleLogoutOperator}>SAIR</button>
      </div>
    </header>
  )
}
