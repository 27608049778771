import React from 'react';
import Table from 'react-table-component-v0';
import { CircularProgress } from '@mui/material';

import styles from './../../assets/styles/MyDay.module.scss';

import formatters from './../../utils/formatters';

import { updateClientPhoneProposal, updateFinalValueProposals } from './../../services/operator.service';

import { useToast } from './../../hooks/useToast';

import ExpandContainerComponent from './ExpandContainerComponent';

function TableComponent({ handleGetProposals, isLoading, columns, handleSetProposals }) {
  const { notifyDanger, notifySuccess } = useToast();

  const proposals = handleGetProposals();

  async function handleSetPhone({ id, phoneNumber }) {
    try {
      const currentProposals = handleGetProposals();
      
      const proposalsToUpdate = [...currentProposals];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === id);
      const proposal = proposalsToUpdate[proposalIndex];
      const additionalPhones = [
        proposal.phone,
        ...proposal.additionalPhones.filter((itemPhone) => phoneNumber !== itemPhone)
      ];

      const updatedProposal = {
        ...proposal,
        phone: phoneNumber,
        additionalPhones,
      }
      
      proposalsToUpdate[proposalIndex] = updatedProposal;
      
      await updateClientPhoneProposal(phoneNumber, additionalPhones, id);

      notifySuccess('Número de telefone principal alterado.');
      handleSetProposals(proposalsToUpdate);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleAddAdditionalPhone({ id, phoneNumber }) {
    try {
      const currentProposals = handleGetProposals();

      const proposalsToUpdate = [...currentProposals];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === id);
      const proposal = proposalsToUpdate[proposalIndex];

      const additionalPhones = [...proposal.additionalPhones, phoneNumber];

      proposalsToUpdate[proposalIndex] = {
        ...proposal,
        additionalPhones,
      };

      await updateClientPhoneProposal(proposal.phone, additionalPhones, id);

      notifySuccess('Número de telefone adicionado.');
      handleSetProposals(proposalsToUpdate);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleRemoveAdditionalPhone({ id, phoneNumber }) {
    try {
      const currentProposals = handleGetProposals();

      const proposalsToUpdate = [...currentProposals];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === id);
      const proposal = proposalsToUpdate[proposalIndex];

      const additionalPhones = proposal.additionalPhones.filter((additionalPhone) => additionalPhone !== phoneNumber);

      proposalsToUpdate[proposalIndex] = {
        ...proposal,
        additionalPhones,
      };

      await updateClientPhoneProposal(proposalsToUpdate.phone, additionalPhones, id);

      notifySuccess('Número de telefone removido.');
      handleSetProposals(proposalsToUpdate);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleUpdateOfferValue({ id, finalValue }) {
    try {
      const offerValue = formatters.numberFloat(finalValue);

      if (isNaN(offerValue)) {
        throw new Error('Valor da oferta inválido.');
      }

      await updateFinalValueProposals(finalValue, id);

      const currentProposals = handleGetProposals();

      const proposalsToUpdate = [...currentProposals];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === id);
      const proposal = proposalsToUpdate[proposalIndex];

      proposalsToUpdate[proposalIndex] = {
        ...proposal,
        finalValue: offerValue,
      };

      notifySuccess('Valor da oferta atualizado.');
      handleSetProposals(proposalsToUpdate);

    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  if (isLoading) {
    return (
      <div className={styles.LoadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Table
      columns={columns}
      rows={proposals}
      hideHeader
      expandContainer={(row) => (
        <ExpandContainerComponent row={row} handleSetPhone={handleSetPhone} handleAddAdditionalPhone={handleAddAdditionalPhone} handleRemoveAdditionalPhone={handleRemoveAdditionalPhone} handleUpdateOfferValue={handleUpdateOfferValue} />
      )}
    />
  )
}

export default TableComponent;