import { useState } from 'react';
import { RiPencilLine, RiMessage2Line } from 'react-icons/ri';
import { Form } from '@unform/web';
import { Copy } from 'akar-icons';

import { useToast } from '../../../hooks/useToast';

import formatters from '../../../utils/formatters';

import styles from './../../../assets/styles/MyDay.module.scss';

import TableMenuModalComponent from './../TableMenuModalComponent';
import Input from './../../Input';
import Button from './../../Button';

function ContactData({ id, name, value, isEditable, handleUpdateOfferValue }) {
  const [isOpen, setIsOpen] = useState(false);

  const { notifyDanger } = useToast();

  function handleTogglePhoneModal() {
    setIsOpen((currIsOpen) => !currIsOpen);
  }

  function handleCopyToClipboardOfferValue() {
    navigator.clipboard.writeText(value);
  }

  function handleCopyToClipboardWppMessage() {
    if (!value) {
      notifyDanger('Valor da oferta em branco, por favor, defina um valor antes de copiar o template.')
    } else {
      const messageToClipboard = `O valor da nossa oferta é de ${value}, lembrando que este é o valor líquido que receberá em até 15 dias úteis após a assinatura do contrato, livre de quaisquer outros encargos.\nNosso preço é baseado no valor que você tem a receber no final do grupo de consórcio.\nVamos fechar negócio?`;

      navigator.clipboard.writeText(messageToClipboard);
    }
  }

  function handleEditOfferValue(value) {
    const offerValue = formatters.numberFloat(value);
    const offerWithMask = formatters.financeReal(offerValue);

    return offerWithMask;
  }

  function handleOnSubmit({ finalValue }) {
    handleUpdateOfferValue({
      id,
      finalValue,
    });

    setIsOpen(false);
  }

  return (
    <div className={styles.ContactDataContainer} style={{ position: 'relative' }}>
      <span>{name}</span>
      <div>
        <strong>{value || '-'}</strong>
        {isEditable && (
          <button type="button" className={styles.CopyButtonContainer} onClick={handleCopyToClipboardWppMessage}>
            {
              value && (
                <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '8px', position: 'absolute' }}>
                  <span>Copiado com sucesso!</span>
                </div>
              )
            }
            <RiMessage2Line size={20} color="#0E5BF0" />
          </button>
        )}
        <button type="button" onClick={handleTogglePhoneModal}>
          {name === 'Valor da oferta' && (
            <div>
              <RiPencilLine size={20} color="#0E5BF0" />
            </div>
          )}
        </button>
        {isOpen && (
          <div className={styles.ContainerModalOfferValue}>
            <TableMenuModalComponent onClose={handleTogglePhoneModal}>
              <div className={styles.ContentModalOfferValue}>
                <Form onSubmit={handleOnSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Valor da oferta</span>
                  <Input name="finalValue" type="text" fnMask={handleEditOfferValue} defaultValue={value} />
                  <Button type='submit'>Salvar</Button>
                </Form>
              </div>
            </TableMenuModalComponent>
          </div>
        )}
        <button type="button" className={styles.CopyButtonContainer} onClick={handleCopyToClipboardOfferValue}>
          <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '8px', position: 'absolute' }}>
            <span>Copiado com sucesso!</span>
          </div>
          <Copy strokeWidth={3} size={17} color="#0E5BF0" />
        </button>
      </div >
    </div >
  )
}

export default ContactData;