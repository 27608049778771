import { useRef } from 'react';

import styles from './../../assets/styles/Modal.module.scss';

import { useOutsideAlerter } from './../../hooks/useOutsideAlerter';

function ModalAlert({ onClose, children }) {
  const modalRef = useRef(null);

  useOutsideAlerter(modalRef, onClose);

  return (
    <div className={styles.Container}>
      <div ref={modalRef}>
        {children}
      </div>
    </div>
  )
}

export default ModalAlert;