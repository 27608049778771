import { Form } from '@unform/web';
import moment from 'moment';
import 'moment/locale/pt-br';

import Input from './../../Input';
import Textarea from './../../Textarea';
import DatePicker from './../../DatePicker';

moment.locale('pt-br');

let selectedDate;

function SchedulingModal({ onSubmit }) {
  function handleOnUpdateDate(date) {
    selectedDate = date;
  }

  function handleOnSubmit({ time, description }) {
    const [hours, minutes] = time.split(':');

    const date = moment(selectedDate).set({ hours: hours, minutes: minutes, seconds: 0 });

    onSubmit(date, description);
  }

  return (
    <Form onSubmit={handleOnSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F1F2F6', padding: '36px', borderRadius: '8px' }}>
      <h1 style={{ fontSize: '20px' }}>Escolha a data do agendamento</h1>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '24px' }}>
        <div style={{ minWidth: '524px' }}>
          <DatePicker onUpdateDate={handleOnUpdateDate} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', width: '500px', height: '379px' }}>
          <Input placeholder="Horário" type="time" required name="time" style={{ width: '100%', height: '52px', padding: '18px 24px', resize: 'none', border: '1px solid #8c8c8c', borderRadius: '8px' }} />
          <Textarea placeholder="Descrição" name="description" style={{ display: 'flex', flex: 1, width: '100%', padding: '18px 24px', resize: 'none', marginTop: '16px', border: '1px solid #8c8c8c', borderRadius: '8px' }} cols="30" rows="10"></Textarea>
        </div>
      </div>
      <button style={{
        width: '100%',
        height: '52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        transition: '0.2s',
        fontWeight: 500,
        letterSpacing: '0.05em',
        marginTop: '16px',
        backgroundColor: '#0E5BF0',
        borderRadius: '8px',
        marginLeft: '8px',
      }}
        type="submit">Criar agendamento</button>
    </Form>
  )
}

export default SchedulingModal;