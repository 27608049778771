import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';

import ToastContext from './../contexts/ToastContext';
// import { Container } from './styles';

function ToastLayout({ children }) {
  function notifyWarn(message) {
    return toast.warn(message);
  }

  function notifyDanger(message) {
    return toast.error(message);
  }

  function notifySuccess(message) {
    return toast.success(message);
  }

  function notifyInfo(message) {
    return toast.info(message);
  }

  return (
    <ToastContext.Provider value={{ notifyWarn, notifyDanger, notifySuccess, notifyInfo }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ToastContext.Provider>)
}

export default ToastLayout;