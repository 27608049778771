import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const api = axios.create({
  baseURL: String(process.env.REACT_APP_API_URL),
});

api.interceptors.request.use(
  config => {
    const token = cookies.get('@ContemplatoSaas/token');
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;