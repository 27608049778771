import { useContext } from 'react';

import ModalContext from './../contexts/ModalContext';

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('Modal context not found, useModal must be used with a Provider.');
  }

  return context;
}