import React, { useState, useCallback, useRef } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import { FaTrashAlt } from 'react-icons/fa';
import Checkbox from '@mui/material/Checkbox';

import styles from './../../assets/styles/Modal.module.scss';

const deniedTabulationOptions = [
  { description: "Recusada - Valor baixo" },
  { description: "Recusada - Grupo perto da data de encerramento" },
  { description: "Recusada - Não quis receber proposta" },
  { description: "Recusada - Não contatar novamente" },
  { description: "Recusada - Cliente irá reativar consórcio" },
  { description: "Recusada - Recebeu a proposta e parou de responder" },
  { description: "Recusada - Outros" },
];

const discardedTabulationOptions = [
  { description: "Descartada - Cota já vendida" },
  { description: "Descartada - Já recebeu/vai receber valor" },
  { description: "Descartada - Outros" },
];

const unassignedTabulationOptions = [
  { description: "Desatribuido - Marcar sem resposta" },
  { description: "Desatribuido - Contato incorreto" },
];

export default function TableModal({ type, onClose, onSubmit, infos }) {
  const reasonRef = useRef(null);
  const descriptionRef = useRef(null);

  const [isOfferValueChecked, setIsOfferValueChecked] = useState(true);
  const [isPhoneNumberChecked, setIsPhoneNumberChecked] = useState(true);

  const modalStyles = {
    'delete-phone': {
      className: styles.ModalDanger,
      icon: <FaTrashAlt color="#fff" size={46} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja deletar “<b>{infos?.phone}</b>”?</span>,
        warningMessage: <span>Após deletar o telefone, não terá mais chance de recuperá-lo.</span>,
        buttonMessage: 'Deletar telefone',
      }
    },
    'move-approve': {
      className: styles.ModalApprove,
      icon: <RiAlertFill color="#fff" size={56} style={{ paddingBottom: '4px' }} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja mover o "<b>{infos?.name}</b>" para o status "<b>Aprovado</b>"?</span>,
        warningMessage: <span>Após mover o status, não terá mais possibilidade de volta.</span>,
        buttonMessage: 'Aprovar contato',
        checkboxes: true,
      }
    },
    'move-deny': {
      className: styles.ModalDanger,
      icon: <RiAlertFill color="#fff" size={56} style={{ paddingBottom: '4px' }} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja mover o "<b>{infos?.name}</b>" para o status "<b>Recusado</b>"?</span>,
        warningMessage: <span>Após mover o status, não terá mais possibilidade de volta.</span>,
        buttonMessage: 'Recusar contato',
        moveReasons: deniedTabulationOptions,
      }
    },
    'move-discard': {
      className: styles.ModalAlert,
      icon: <RiAlertFill color="#fff" size={56} style={{ paddingBottom: '4px' }} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja mover o "<b>{infos?.name}</b>" para o status "<b>Descartado</b>"?</span>,
        warningMessage: <span>Após mover o status, não terá mais possibilidade de volta.</span>,
        buttonMessage: 'Descartar contato',
        moveReasons: discardedTabulationOptions,
      }
    },
    'move-no-reply': {
      className: styles.ModalAlert,
      icon: <RiAlertFill color="#fff" size={56} style={{ paddingBottom: '4px' }} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja marcar o "<b>{infos?.name}</b>" para "<b>Sem Resposta</b>"?</span>,
        warningMessage: <span>Após mover o status, não terá mais possibilidade de volta.</span>,
        buttonMessage: 'Marcar sem resposta',
      }
    },
    'remove-assignment': {
      className: styles.ModalAlert,
      icon: <RiAlertFill color="#fff" size={56} style={{ paddingBottom: '4px' }} />,
      info: {
        title: 'Você tem certeza?',
        message: <span>Você tem certeza que deseja desatribuir o "<b>{infos?.name}</b>"?</span>,
        warningMessage: <span>Caso use as desatribuições com descuido, não terá mais possibilidade de volta.</span>,
        buttonMessage: 'Desatribuir',
        moveReasons: unassignedTabulationOptions,
      }
    },
  }

  const handleToggleOfferValueCheckbox = useCallback(() => {
    setIsOfferValueChecked((isChecked) => !isChecked);
  }, []);

  const handleTogglePhoneNumberCheckbox = useCallback(() => {
    setIsPhoneNumberChecked((isChecked) => !isChecked);
  }, []);

  const handleOnSubmit = useCallback(() => {
    const reason = reasonRef.current?.value;
    const description = descriptionRef.current?.value;

    onSubmit({
      reason,
      description: description || null,
    });
  }, [onSubmit]);

  const modalStyle = modalStyles[type];

  if (!modalStyle) {
    return (
      <div />
    )
  }

  return (
    <div className={modalStyle.className}>
      <div className={styles.ModalContainer}>
        <div className={styles.ModalBar}>
          <div>
            {modalStyle.icon}
          </div>
        </div>
        <div className={styles.ModalContent}>
          <h1>{modalStyle.info.title}</h1>
          {modalStyle.info.message}
          <div className={styles.ModalAttention}>
            <div />
            <div>
              <RiAlertFill size={24} />
              <div>
                <strong>Atenção!</strong>
                {modalStyle.info.warningMessage}
              </div>
            </div>
          </div>
          {
            modalStyle.info.checkboxes && (
              <div className={styles.ModalCheckboxes}>
                <div>
                  <Checkbox checked={isOfferValueChecked} onChange={handleToggleOfferValueCheckbox} />
                  <span>Confirmo o valor da oferta em <b>{infos?.finalValue}</b></span>
                </div>
                <div>
                  <Checkbox checked={isPhoneNumberChecked} onChange={handleTogglePhoneNumberCheckbox} />
                  <span>Confirmo o telefone principal <b>{infos?.phone}</b></span>
                </div>
              </div>
            )
          }
          {
            modalStyle.info.moveReasons && (
              <div style={{ marginTop: '16px' }}>
                <select name="" id="" ref={reasonRef} style={{ width: '100%', borderRadius: '8px', height: '52px', padding: '0 24px', border: '1px solid #DDDDDD', transition: '0.1s', color: '#25213B' }}>
                  {
                    modalStyle.info.moveReasons.map((reason) => <option key={reason.description} value={reason.description}>{reason.description}</option>)
                  }
                </select>
                <textarea placeholder='Descrição' cols="30" rows="10" ref={descriptionRef} style={{ width: '100%', height: '100px', resize: 'none', marginTop: '8px', borderRadius: '8px', border: '1px solid #DDDDDD', padding: '16px 24px' }} />
              </div>
            )
          }
          <div className={styles.ModalButtonsContainer}>
            <button onClick={onClose} type="button">Cancelar</button>
            <button onClick={handleOnSubmit} type="button" disabled={!isOfferValueChecked || !isPhoneNumberChecked}>{modalStyle.info.buttonMessage}</button>
          </div>
        </div>
      </div>
    </div>
  )
}
