import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import styles from './../../../assets/styles/MyDay.module.scss';

import { useToast } from './../../../hooks/useToast';
import { useAuth } from './../../../hooks/useAuth';

import PhoneNumberComponent from './../PhoneNumberComponent';
import ContactData from './../ContactData';

import formatters from '../../../utils/formatters';

function ExpandContainerComponent({ row, handleSetPhone, handleAddAdditionalPhone, handleRemoveAdditionalPhone, handleUpdateOfferValue }) {
  const [proposalData, setProposalData] = useState({});
  const [assigneeData, setAssigneeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { notifyWarn, notifyDanger } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    async function requestProposalData() {
      try {
        const { getProposalById } = await import('./../../../services/proposal.service');
        const { getAssignees } = await import('./../../../services/assignee.service');

        const proposal = await getProposalById(row.id);
        const assignees = await getAssignees();
        proposal.finalValue = row.finalValue;

        setProposalData(proposal);
        setAssigneeData(assignees);
        setIsLoading(false);
      } catch (err) {
        notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
      }
    }

    requestProposalData();
  }, [row, notifyDanger]);

  function handleCreatePhone() {
    const phoneNumber = prompt('Digite o número de telefone que deseja adicionar.');

    if (phoneNumber) {
      const hasPhone = row.additionalPhones.find((additionalPhone) => additionalPhone === phoneNumber);
      
      if (hasPhone || phoneNumber === row.phone) {
        notifyWarn('Telefone já adicionado, por favor, verifique a lista novamente.')
      } else {
        handleAddAdditionalPhone({ id: row.id, phoneNumber });
      }
    }
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '16px' }}>
        <CircularProgress size={24} />
      </div>
    )
  }
  
  if (row.operatorId !== user.id) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '16px' }}>
        <span>Não atribuido</span>
      </div>
    )
  }

  const contactPhones = [row.phone, ...row.additionalPhones];

  return (
    <div className={styles.ExpandContainerComponent}>
      <div>
        <div>
          <strong>TELEFONES</strong>
          <button type="button" onClick={handleCreatePhone}>
            <AiFillPlusCircle color="#0E5BF0" style={{ marginLeft: '8px' }} size={19} />
          </button>
        </div>
        <div>
          {
            contactPhones.map((phoneNumber) => (
              <PhoneNumberComponent key={phoneNumber} id={row.id} isPrimary={row.phone === phoneNumber} phoneNumber={phoneNumber} name={row.name} handleSetPhone={handleSetPhone} handleRemovePhone={handleRemoveAdditionalPhone} />
            ))
          }
        </div>
      </div>
      <div>
        <div>
          <div>
            <h1>Dados do contato</h1>
            <span>Todos os dados disponíveis do contato:</span>
          </div>
          <Link to={`/historic/${row.id}`} target="_blank">Ver histórico</Link>
        </div>
        <div>
          <div>
            <ContactData name="Administradora" value={proposalData.quota.administrator} />
            <ContactData name="Fundo comum a receber" value={formatters.financeReal(proposalData.quota.toReceiveCommonFundValue)} />
            <ContactData name="Valor da parcela" value={formatters.financeReal(proposalData.quota.monthlyInstallmentValue)} />
          </div>
          <div>
            <ContactData name="Grupo" value={proposalData.quota.groupId} />
            <ContactData name="Montante já pago" value={formatters.financeReal(proposalData.quota.paidValue)} />
            <ContactData name="Saldo devedor" value={formatters.financeReal(proposalData.quota.remainingValue)} />
          </div>
          <div>
            <ContactData name="Cota" value={formatters.financeReal(proposalData.quota.quotaId)} />
            <ContactData name="Valor do crédito" value={formatters.financeReal(proposalData.quota.creditValue)} />
            <ContactData name="Data de vencimento" value={moment(proposalData.quota.groupEndDate).format('DD/MM/YYYY')} />
          </div>
          <div>
            <ContactData name="Tipo" value={proposalData.quota.assetType} />
            <ContactData name="Oferta mínima" value={formatters.financeReal(proposalData.minimumValue)} />
            <ContactData name="Oferta máxima" value={formatters.financeReal(proposalData.maximumValue)} />
          </div>
          <div>
            <ContactData name="Cessionário" value={assigneeData[proposalData.assigneeId]} />
            <ContactData name="Onboarding" value={proposalData.urlOnboarding ? proposalData.urlOnboarding : "Indisponível"} />
            <ContactData id={row.id} name="Valor da oferta" value={formatters.financeReal(proposalData.finalValue)} handleUpdateOfferValue={handleUpdateOfferValue} isEditable />
          </div>
        </div>
      </div>
    </div>
  )
}

const ExpandContainer = React.memo(ExpandContainerComponent, (prev, curr) => {
  return JSON.stringify(prev.row) === JSON.stringify(curr.row)
});

export default ExpandContainer;