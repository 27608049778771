const financeReal = number => number?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

const toNumber = number => financeReal(number).substring(3);

const numberFloat = (numberString) => parseFloat(numberString.replace(/\D/g, '')) / 100;

const toDecimalNumber = number => Number(Math.round(number+'e2')+'e-2');

const phoneBrazil = (v) => {
    v=v.replace(/\D/g,""); //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v=v.replace(/(\d)(\d{4})$/,"$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
}

const jsonToQuerystring = (jsonObject) => {
    let querystring = '';
    for (let [key, value] of Object.entries(jsonObject)) {
        querystring = querystring.concat(`${key}=${value}&`)
    }
    return querystring;
}


const formatters = {
    financeReal,
    toDecimalNumber,
    toNumber,
    jsonToQuerystring,
    phoneBrazil,
    numberFloat,
}
export default formatters;