import { useContext } from 'react';

import AuthContext from './../contexts/AuthContext';

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Auth context not found, useAuth must be used with a Provider.');
  }

  return context;
}