import api from './api';
import Cookies from 'universal-cookie';

export const login = async (email, password) => {
    try {
        const response = await api.post('/auth/login', {
            email,
            password,
        });

        const cookies = new Cookies();

        cookies.set('@ContemplatoSaas/user', response?.data.user, { path: '/' });
        cookies.set('@ContemplatoSaas/token', response?.data.token, { path: '/' });

        return response?.data;
    } catch (err) {
        console.log(err)
        err.message = err.status === 401 ? 'email ou senha inválidos' : 'erro ao fazer login, tente novamente mais tarde';
        throw err;
    }
    
}

export const logout = async () => {
    const cookies = new Cookies();

    cookies.remove('@ContemplatoSaas/user', { path: '/' });
    cookies.remove('@ContemplatoSaas/token', { path: '/' });
}