import api from './api';

export const updateFinalValueProposals = async (proposals, id) => {
  try {
    const response = await api.patch(`/proposals/${id}/finalValue`, {
      finalValue: proposals,
    });
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const openVollChat = async (proposalId) => {
  try {
    const response = await api.post(`/messaging/init-chat`, {
      proposalId,
    });
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}


export const updateStatusProposals = async ({
  id,
  status,
  details,
  proposalId,
  operatorId,
  interact,
  type,
}) => api.patch(`/proposals/${id}/status/v2`, {
  status,
  details,
  proposalId,
  operatorId,
  interact,
  type,
});

export const contactNoResponse = async ({ id, interact, description }) => {
  try {
    const response = await api.patch(`/proposals/${id}/handleNoResponse`, {
      interact,
      description,
    });

    return response?.data;
  } catch (err) {
    throw err;
  }
}

export const updateAssignProposalToOperator = async ({ id, interact, description }) => {
  try {
    const response = await api.patch(`/proposals/${id}/assignToRobot`, {
      interact,
      description,
    });

    return response?.data;
  } catch (err) {
    throw err;
  }
}

export const updateClientPhoneProposal = async (phone, additionalPhones, id) => {
  try {
    const response = await api.patch(`/proposals/${id}/clientPhone/v2`, {
      phone,
      additionalPhones,
    });
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const updateAssignProposalToMe = async (id) => {
  try {
    const response = await api.patch(`/proposals/${id}/assignToMe`);
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const getAssignedToOperator = async () => {
  try {
    const response = await api.get(`/proposals/assignedToOperator/v2`);
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const getAssignedToOperatorScheduled = async () => {
  try {
    const response = await api.get(`/proposals/assignedToOperatorScheduled`);
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const consultAssigneds = async (params) => {
  try {
    const response = await api.get(`/proposals/assigneds`, {
      params,
    });
    return response?.data;
  } catch (err) {
    if (err.response.status === 404) {
      throw new Error('Nenhum cliente encontrado.');
    }
    console.log(err)
    throw err;
  }
}

export const createInteract = async (interact) => {
  try {
    const response = await api.post(`/follow-up`, interact);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getAllInteracts = async (proposalId) => {
  try {
    const response = await api.get(`/follow-up?proposalId=${proposalId}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
}

export const getOperatorsByStep = async () => {
  try {
    const response = await api.get(`/proposals/operatorByStep`);
    return response?.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}

export const getTemplatesMessage = async () => {
  try {
    const response = await api.get('/messaging/templates');
    return response?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const sendMessage = (proposalIds, templateName, assigneeId) => api.post('/messaging/send-message', { proposalIds, templateName, assigneeId })