import React from 'react';

import Auth from './Auth';

import Operator from './Operator';
import Toast from './Toast';
// import Talk from './Talk';
import Modal from './Modal';

function Layouts() {
  return (
    <Toast>
      <Modal>
        <Auth>
          {/* <Talk> */}
            <Operator />
          {/* </Talk> */}
        </Auth>
      </Modal>
    </Toast>
  )
}

export default Layouts;