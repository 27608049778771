import api from './api';
import formatters from '../utils/formatters';
import moment from "moment";

export const createProposals = async (quotaIdList, assigneeId, consultantId) => {
    try {
        const response = await api.post(`/proposals/bulk`, {
            quotaIdList,
            assigneeId,
            consultantId,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const createSchedule = async (proposalId, date, description, type) => {
    try {
        const response = await api.post(`/contact-scheduling/create`, {
            proposalId,
            date: date.format('YYYY-MM-DDTHH:mm'),
            description,
            type,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const updateSchedule = async (scheduleId, date, description) => {
    try {
        const response = await api.patch(`/contact-scheduling/${scheduleId}`, {
            date: date.format('YYYY-MM-DDTHH:mm'),
            description: description,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const deleteSchedule = async (scheduleId) => {
    try {
        const response = await api.delete(`/contact-scheduling/${scheduleId}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const updateOperator = async (quotaIdList, operatorId) => {
    try {
        const response = await api.post(`/proposals/operator`, {
            quotaIdList,
            operatorId,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOwnedProposals = async (filters) => {
    try {
        const querystring = formatters.jsonToQuerystring(filters);
        const response = await api.get(`/proposals/owned?${querystring}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOwnedAcquisitions = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/ownedAcquisitions?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getAggregatedProposals = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/aggregated?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboard = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboard?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboardAssignee = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboardAssignee?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboardConsultant = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboardConsultant?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getFollowUpsAggregated = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/aggregatedFollowUps?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOperationDetails = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/operationDetails?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getProposalById = async (proposalId) => {
    try {
        const response = await api.get(`/proposals/find/${proposalId}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const enrichClientData = async (proposalIds) => api.post('/proposals/enrich', { proposalIds });
