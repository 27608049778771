import React, { useState, useCallback, useMemo } from 'react';
import Cookies from 'universal-cookie';

import AuthContext from './../contexts/AuthContext';

import { login, logout } from './../services/auth.service';

function AuthLayout({ children }) {
  const cookies = useMemo(() => new Cookies(), []);
  const [user, setUser] = useState(() => {
    const storagedUser = cookies.get('@ContemplatoSaas/user');

    if (storagedUser) {
      return storagedUser;
    }

    return '';
  });
  const [token, setToken] = useState(() => {
    const storagedToken = cookies.get('@ContemplatoSaas/token');

    if (storagedToken) {
      return storagedToken;
    }

    return '';
  });

  const handleSignIn = useCallback(async ({ email, password }) => {
    const data = await login(email, password);

    if (data.token) {
      setToken(data.token);
      setUser(data.user);
    }

    return data;
  }, []);

  const handleSignOut = useCallback(async () => {
    logout();

    setToken('');
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, handleSignIn, handleSignOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthLayout;
