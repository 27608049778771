import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Layouts from './../layouts';

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Layouts />
      </Suspense>
    </BrowserRouter>
  )
}
