import status from './../enums/status';

export const proposalReducer = (state, action) => {
  switch (action.type) {
    case 'FETCHED_PROPOSAL':
      return [...action.proposals];
    case 'REMOVE_PROPOSAL':
      return state.filter((proposal) => proposal.id !== action.id);
    case 'UPDATE_PROPOSAL_DATE': {
      const proposalsToUpdate = [...state];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === action.id);
      const proposal = proposalsToUpdate[proposalIndex];

      proposalsToUpdate[proposalIndex] = {
        ...proposal,
        contactSchedulingDate: action.date,
        contactSchedulingDescription: action.description,
      };

      return proposalsToUpdate;
    }
    case 'UPDATE_PROPOSAL_OWNER': {
      const proposalsToUpdate = [...state];
      const proposalIndex = proposalsToUpdate.findIndex((itemProposal) => itemProposal.id === action.id);
      const proposal = proposalsToUpdate[proposalIndex];

      if (proposal.status === status.INPROGRESS) {
        proposalsToUpdate[proposalIndex] = {
          ...proposal,
          operatorId: action.operatorId,
          status: status.INNEGOTIATION,
        };
      } else {
        proposalsToUpdate[proposalIndex] = {
          ...proposal,
          operatorId: action.operatorId,
        };
      }
      return proposalsToUpdate;
    }
    default:
      return state;
  }
};