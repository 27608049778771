import React, { useLayoutEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import api from './../services/api';

import { useAuth } from './../hooks/useAuth';
import { useToast } from './../hooks/useToast';

const PrivateRoute = ({ isPrivate }) => {
  const { notifyDanger } = useToast();
  const { handleSignOut } = useAuth();

  const { token } = useAuth();

  const auth = !!token; // determine if authorized, from context or however you're doing it

  useLayoutEffect(() => {
    if (token) {
      const expiredTokenInterceptor = api.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response.status === 401) {
          notifyDanger('Token expirado, por favor, realize o login novamente.');
          handleSignOut();
        } else {
          return Promise.reject(error);
        }
      });
  
      return () => {
        api.interceptors.response.eject(expiredTokenInterceptor);
      }
    }
  }, [notifyDanger, handleSignOut, token]);

  if (auth) {
    if (isPrivate) {
      return <Outlet />;
    }

    return <Navigate to="/" />;
  } else {
    if (isPrivate) {
      return <Navigate to="/auth/signin" />;
    }

    return <Outlet />;
  }
}

export default PrivateRoute;