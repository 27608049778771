import moment from 'moment';

import { updateStatusProposals, contactNoResponse, updateAssignProposalToMe, updateAssignProposalToOperator } from './../../../services/operator.service';
import { createSchedule, deleteSchedule, updateSchedule } from '../../../services/proposal.service';

import formatters from './../../../utils/formatters';

import TableModal from './../../Modals/TableModal';
import SchedulingModalMyDay from './../SchedulingModalMyDay';
import SchedulingModalMySchedules from './../SchedulingModalMySchedules';

import { useAuth } from './../../../hooks/useAuth';
import { useModal } from './../../../hooks/useModal';
import { useToast } from './../../../hooks/useToast';

import styles from './../../../assets/styles/MyDay.module.scss';

import status from './../../../enums/status';

function TableMenu({ handleRemoveProposal, handleUpdateDate, handleUpdateOwner, row }) {
  const { handleOpenModal, handleCloseModal } = useModal();
  const { notifyDanger, notifySuccess } = useToast();
  const { user } = useAuth();

  const { id, phone, name, operatorId, finalValue, contactSchedulingId, contactSchedulingDate, contactSchedulingDescription } = row;

  const modalInfos = {
    name,
    phone: formatters.phoneBrazil(phone),
    finalValue: formatters.financeReal(finalValue),
  }

  async function handleOnUpdateScheduling(date, description) {
    try {
      handleCloseModal();

      await updateSchedule(contactSchedulingId, date, description);

      notifySuccess('Agendamento criado com sucesso.');
      handleRemoveProposal(contactSchedulingId);
      handleUpdateDate({ id, date, description });
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleAssignToMe() {
    try {
      await updateAssignProposalToMe(id);

      handleUpdateOwner({ id, operatorId: user.id });
      notifySuccess('Atribuido com sucesso.');
    } catch(err) {
      notifyDanger(err.message)
    }
  }

  async function handleOnDeleteSchedule() {
    handleCloseModal();
    await deleteSchedule(contactSchedulingId);
    notifySuccess('Agendamento deletado com sucesso!');
    handleRemoveProposal(id);
  }

  async function handleOnApprove() {
    try {
      handleCloseModal();
      
      await updateStatusProposals({
        id,
        status: status.ACCEPTED,
      });

      notifySuccess('Aprovado com sucesso!');
      handleRemoveProposal(id);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleOnDeny({ reason, description }) {
    try {
      handleCloseModal();

      await updateStatusProposals({
        id,
        interact: reason,
        details: description,
        type: 'manual',
        status: status.DENIED,
      });
      
      notifySuccess('Recusado com sucesso!');
      handleRemoveProposal(id);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }
  
  async function handleOnRemoveAssignment(props) {
    try {
      handleCloseModal();
      
      if (props.reason === "Desatribuido - Marcar sem resposta") {
        const proposal = await contactNoResponse({ id, interact: "Desatribuido - Marcar sem resposta", description: props?.description });

        if (!proposal.active) {
          handleRemoveProposal(id);
        } else {
          handleUpdateOwner({ id, operatorId: proposal?.programmaticallyUserId });
        }
      } else if (props.reason === "Desatribuido - Contato incorreto") {
        const { programmaticallyUserId } = await updateAssignProposalToOperator({ id, interact: "Desatribuido - Contato incorreto", description: props?.description });

        handleUpdateOwner({ id, operatorId: programmaticallyUserId });
      }
      
      notifySuccess('Desatribuido com sucesso!');
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleOnDiscard({ reason, description }) {
    try {
      handleCloseModal();

      await updateStatusProposals({
        id,
        interact: reason,
        details: description,
        type: 'manual',
        status: status.DISCARDED,
      });
      
      notifySuccess('Descartado com sucesso!');
      handleRemoveProposal(id);
    } catch (err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }

  async function handleOnSubmitScheduling(date, description) {
    try {
      handleCloseModal();

      await createSchedule(id, date, description, 'manual');

      notifySuccess('Agendamento criado com sucesso.');
      handleRemoveProposal(id);
    } catch(err) {
      notifyDanger(err.response.data || 'Erro ao executar tarefa, consulte suporte.');
    }
  }
  
  function handleShowApproveModal() {
    handleOpenModal({
      content: <TableModal type="move-approve" onClose={handleCloseModal} onSubmit={handleOnApprove} infos={modalInfos} />
    });
  }
  
  function handleShowDenyModal() {
    handleOpenModal({
      content: <TableModal type="move-deny" onClose={handleCloseModal} onSubmit={handleOnDeny} infos={modalInfos} />
    });
  }
  
  function handleShowRemoveAssignment() {
    handleOpenModal({
      content: <TableModal type="remove-assignment" onClose={handleCloseModal} onSubmit={handleOnRemoveAssignment} infos={modalInfos} />
    });
  }
  
  function handleShowDiscardModal() {
    handleOpenModal({
      content: <TableModal type="move-discard" onClose={handleCloseModal} onSubmit={handleOnDiscard} infos={modalInfos} />
    });
  }

  function handleOpenMyDayCalendar() {
    handleOpenModal({
      content: <SchedulingModalMyDay onSubmit={handleOnSubmitScheduling} />
    });
  }

  const defaultDate = moment(contactSchedulingDate).toDate();

  function handleOpenMyScheduleCalendar() {
    handleOpenModal({
      content: <SchedulingModalMySchedules defaultDate={defaultDate} defaultDescription={contactSchedulingDescription} onDelete={handleOnDeleteSchedule} onSubmit={handleOnUpdateScheduling} />
    });
  }

  if (operatorId !== user.id) {
    return (
      <div className={styles.MenuContent}>
        <a onClick={handleAssignToMe} href="#">Atribuir para mim</a>
      </div>
    )
  }

  if (contactSchedulingId) {
    return (
      <div className={styles.MenuContent}>
        <a onClick={handleOpenMyScheduleCalendar} href="#">Editar agendamento</a>
        <a onClick={handleShowRemoveAssignment} href="#">Remover atribuição</a>
        <div />
        <a onClick={handleShowApproveModal}>Aprovar</a>
        <a onClick={handleShowDenyModal} href="#">Recusar</a>
        <a onClick={handleShowDiscardModal} href="#">Descartar</a>
      </div>
    )
  }

  return (
    <div className={styles.MenuContent}>
      <a onClick={handleOpenMyDayCalendar} href="#">Criar agendamento</a>
      <a onClick={handleShowRemoveAssignment} href="#">Remover atribuição</a>
      <div />
      <a onClick={handleShowApproveModal}>Aprovar</a>
      <a onClick={handleShowDenyModal} href="#">Recusar</a>
      <a onClick={handleShowDiscardModal} href="#">Descartar</a>
    </div>
  )
}

export default TableMenu;