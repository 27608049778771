import React, { lazy, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './../routes/PrivateRoute';

import OperatorContext from './../contexts/OperatorContext';

import MyDay from './../views/MyDay';
const SignIn = lazy(() => import('./../views/SignIn'));
const Historic = lazy(() => import('../views/Historic'));
const MySchedules = lazy(() => import('../views/MySchedules'));
const Dashboard = lazy(() => import('./../views/Dashboard'));
const CompleteVision = lazy(() => import('./../views/CompleteVision'));

function OperatorLayout() {
  const [operator, setOperator] = useState({});

  function handleSetOperator({ companyId, id, role }) {
    setOperator({
      id,
      role,
      companyId,
    });
  }

  return (
    <OperatorContext.Provider value={{ operator, handleSetOperator }}>
      <Routes>
        <Route path="/" element={<PrivateRoute isPrivate />}>
          <Route exact path="/" element={<MyDay />} />
          <Route path="/schedules" element={<MySchedules />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/complete-vision" element={<CompleteVision />} />
          <Route path="/historic/:id" element={<Historic />} />
        </Route>
        <Route path="/auth" element={<PrivateRoute />}>
          <Route path="/auth/signin" element={<SignIn />} />
        </Route>
        <Route path="/*" element={<div>Ta perdido ae amigo?</div>} />
      </Routes>
    </OperatorContext.Provider>
  )
}

export default OperatorLayout;